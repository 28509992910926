import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

class AnimateLayout {
    constructor() {
        this.breakpoint = 768;

        console.log('startr');
        this.animate();
    }

    animate() {
        mm.add(`(min-width: ${this.breakpoint}px)`, () => {
            // Hero section
            this.hero = document.querySelector('.hero');

            const heroTl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.hero,
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                },
            });

            heroTl.to(this.hero, {
                yPercent: 100,
                opacity: 0.5,
                ease: 'none',
            });

            // // Join section
            // this.join = document.querySelector('.join');

            // const joinTl = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: this.hero,
            //         start: `bottom-=${window.innerHeight}px top`,
            //         end: 'bottom top',
            //         scrub: true,
            //     },
            // });

            // joinTl.from(this.join, {
            //     yPercent: -100,
            //     ease: 'none',
            // });
        });
    }
}

export default new AnimateLayout();
