import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

class AnimateContent {
    constructor() {
        this.headerTl = gsap.timeline();
        this.heroTl = gsap.timeline();
        this.aboutTl = gsap.timeline();
        this.joinTl = gsap.timeline();

        this.isInit = false;
        this.isMobile = window.innerWidth < 768;

        mm.add('(min-width: 768px)', () => {
            this.animateHeader();
            this.animateHero();
            this.animateAbout();
            this.animateJoin();

            return () => {
                this.isInit = false;
            };
        });
    }

    animateHeader() {
        this.headerTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.hero',
                start: 'top-=100 top',
                end: 'bottom+=100 bottom',
                id: 'header',
            },
        });

        this.headerTl.from('.header', { y: -50, opacity: 0, duration: 1 });

        ScrollTrigger.getById('header').disable();
    }

    animateHero() {
        this.heroTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.hero',
                start: 'top-=100 top',
                end: 'bottom+=100 bottom',
                id: 'hero',
            },
        });

        this.heroTl
            .from('.hero h1 div', {
                y: 100,
                opacity: 0,
                duration: 1,
                delay: 1,
            })
            .from(
                '.hero .text',
                {
                    opacity: 0,
                    duration: 1,
                },
                '-=.2'
            );

        ScrollTrigger.getById('hero').disable();
    }

    animateAbout() {
        this.aboutTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.about',
                start: 'start+=100px bottom',
                end: 'bottom top',
                id: 'about',
            },
        });

        this.aboutTl
            .from('.about h2 div', {
                y: 100,
                opacity: 0,
                duration: 1,
                delay: 0.5,
            })
            .from(
                '.about .text',
                {
                    opacity: 0,
                    duration: 1,
                },
                '-=.5'
            )
            .from(
                '.about button',
                {
                    opacity: 0,
                    duration: 1,
                },
                '-=.5'
            );

        ScrollTrigger.getById('about').disable();
    }

    animateJoin() {
        this.joinTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.join',
                start: 'start+=100px bottom',
                end: 'bottom top',
                id: 'join',
            },
        });

        this.joinTl.from('.join-content', {
            y: 100,
            opacity: 0,
            duration: 1,
            delay: 0.5,
        });

        ScrollTrigger.getById('join').disable();
    }

    init() {
        ScrollTrigger.getById('header').enable();
        ScrollTrigger.getById('hero').enable();
        ScrollTrigger.getById('about').enable();
        ScrollTrigger.getById('join').enable();
        this.isInit = true;
    }
}

export default new AnimateContent();
